import { AgentModeService } from './../agent-mode.service';
import { SiebelConfigService } from './../siebel-config.service';
import { ServiceSettings, ServiceSettingsKey } from './../Model/Enums/ServiceSettingsKey';
import { DriverSettings, DriverSettingsKey } from './../Model/Enums/DriverSettingsKey';
import { SCCommandFlag } from '../Model/Enums/SCCommandFlag';
import { ISiebelCommand } from '../Model/ISiebelCommand';
import { ScapiService } from '../scapi.service';
import { Component, OnInit } from '@angular/core';
import { getSearchLayout } from '../util/SearchLayout';
import { getClickToDialLayout } from '../util/ClickToDialLayout';
import { ICrmEntity } from '../Model/ICrmEntity';
import { bind } from 'bind-decorator';
import {
  Application,
  getCadPopKeys,
} from '@amc-technology/applicationangularframework';
import { IActivity } from '../Model/IActivity';
import { IActivityDetails } from '../Model/IActivityDetails';
import { Presence, Reason } from '../Model/Enums/Presence';
import { StorageService } from '../storage.service';
import { LoggerService } from '../logger.service';
import { ClickToActService } from '../click-to-act.service';
import { IClickToDialLayout } from '../Model/IClickToDialLayout';
import { ISearchLayout, ISearchLayoutObject } from '../Model/ISearchLayout';
import { getCadDisplayConfig } from '../util/DisplayCADInfo';
import { ICadDisplayConfig } from '../Model/ICadDisplayConfig';
import {
  CHANNEL_TYPES,
  IAppConfiguration,
  IContextualContact,
  IGetPresenceResult,
  IInteraction,
  INTERACTION_DIRECTION_TYPES,
  INTERACTION_STATES,
  ISupportedChannel,
  LOG_LEVEL,
  NOTIFICATION_TYPE,
  RecordItem,
  SearchLayouts,
  SearchRecords,
  addContextualContacts,
  clearContextualContacts,
  clickToDial,
  getPresence,
  logout,
  registerContextualControls,
  registerEnableClickToDial,
  registerOnLogout,
  registerOnPresenceChanged,
  registerScreenpop,
  registerSetSupportedChannels,
  registerSendNotification,
  sendNotification,
  setPresence,
} from '@amc-technology/davinci-api';
import { SupportedCommands } from '../Model/Enums/Commands';
import { LEGACY_WORKMODE } from '../Model/Enums/LegacyWorkmode';
import { EVENT_CODES } from '../Model/Enums/EventCodes';
import { CallStateEvent } from '../Model/CallStateEvent';
import { Call, CallProperties } from '../Model/Call';
import { AMC_CONNECTION_STATES } from '../Model/Enums/AmcConnectionStates';
import { CallParty } from '../Model/CallParty';
import { CommandParameter } from '../Model/Enums/CommandParameter';
import { DataStoreService } from '../data-store.service';
import { AGENT_MODES } from '../Model/Enums/AgentModes';
import { SiebelCommandStatusService } from '../siebel-command-status.service';

@Component({
  selector: 'app-home',
  templateUrl: 'home-siebel.component.html',
})
export class HomeComponent extends Application implements OnInit {
  private maxRecordsDefault = 50;
  public searchLayout: ISearchLayout;
  private lastSearchedCTDNumber: string;
  private cadDisplayConfig: ICadDisplayConfig;
  private clickToDialLayout: IClickToDialLayout;
  private contextualContacts: { [key: string]: IContextualContact } = {};
  private phoneNumberFormat: Object;
  public quickCommentList: string[];
  protected enableAutoSave: boolean;
  public enableCallActivity: boolean;
  public activityLayout: any;
  private lastClickToDialRecords: SearchRecords;
  private lastClickToDialIActivityDetails: IActivityDetails = null;
  private screenpopOnAlert: boolean;
  protected cadActivityMap: Object;
  public clickToDialPhoneReformatMap: Object;
  public DisplayQuickCreate: boolean;
  protected QuickCreateEntities: any;
  public quickCommentOptionRequiredCadArray: any;
  private softphoneWidth: any;
  private channels: ISupportedChannel;
  private delayActivitySave: number;
  private garbageCollectionFrequency: number;
  private scenarioExpirationTime: number;
  private clickToDialTriggered = false;
  private cname = "Siebel - HomeComponent";
  private channelToSiebelPresenceMap: Map<string, string> = new Map([]);
  private siebelToChannelMap: Map<string, string> = new Map([]);
  private currentInteraction: IInteraction;
  private interactionToDisposition: IInteraction;
  private sendPresence = true;
  private driverInitialized = false;
  private channelTypeMap = {
    inbound: undefined,
    outbound: undefined
  };
  private numericalReasonMap: Map<string, string> = new Map([]);
  private bIsConsultCallRetrieved = false;

  constructor(
    private loggerService: LoggerService,
    public storageService: StorageService,
    private scapi: ScapiService,
    private clickToActService: ClickToActService,
    private siebelConfig: SiebelConfigService,
    private dataStoreService: DataStoreService,
    private agentModeService: AgentModeService,
    private commandStatus: SiebelCommandStatusService
  ) {
    super(loggerService.logger);
    this.storageService.syncWithLocalStorage();
    this.phoneNumberFormat = {};
    this.lastClickToDialRecords = null;
    this.screenpopOnAlert = true;
    this.lastSearchedCTDNumber = '';
    this.clickToDialPhoneReformatMap = {};
    this.enableCallActivity = true;
    this.enableAutoSave = true;
    this.cadActivityMap = {};
    this.quickCommentOptionRequiredCadArray = {};
    this.delayActivitySave = 0;
    this.garbageCollectionFrequency = 5000;
    this.scenarioExpirationTime = 10000;
  }

  async ngOnInit() {
    try {
      this.logger.logDebug(
        'Siebel - Home : START : Fetching Siebel App Configuration'
      );
      await this.loadConfig();

      this.bridgeScripts = this.bridgeScripts.concat([
        this.getBridgeURL()
      ]);

      // Get width from Creators Studio
      this.softphoneWidth = parseInt(this.appConfig.variables.toolbarWidth, 10);

      registerScreenpop(async (channelType, interactionDirection, objID, objType) => {
        const entity = {
          id: objID,
          type: objType
        }
        this.bridgeEventsService.sendEvent(
          'agentSelectedCallerInformation',
          entity
        );
      });

      const appName = this.appConfig.name;
      registerSetSupportedChannels(async (appName, channels) => {
        this.bridgeEventsService.sendEvent(
          'sendSupportedChannels',
          // Sort channels by channelType to guarantee the same order every time
          channels.sort((a, b) => a.channelType <= b.channelType ? 1 : -1)
        );
      });

      registerSendNotification(this.handleNotification);
      await super.ngOnInit();
      this.getActivityLayout();

      this.scapi.initialize(this.bridgeEventsService);
      this.dataStoreService.initialize({bridgeEventService: this.bridgeEventsService, loggerService: this.loggerService});

      this.scapi.$InvokeCommand.subscribe(this.InvokeCommand);

      let commandsAlwaysOn = async () => {
        await this.scapi.SetCommands([SupportedCommands.LogOut, SupportedCommands.MakeCall, SupportedCommands.SetAgentReady, SupportedCommands.SendCAD], [0, 0, 0, 0]);
      }

      commandsAlwaysOn();

      if (!isNaN(this.softphoneWidth)) {
        this.bridgeEventsService.sendEvent(
          'setDynamicsSoftphoneWidth',
          this.softphoneWidth
        );
      }

      this.bridgeEventsService.sendEvent(
        'updateActivityLayout',
        this.activityLayout
      );
      this.bridgeEventsService.sendEvent(
        'setObjects',
        this.searchLayout.objects
      );
      this.bridgeEventsService.subscribe('driverInitialized', this.driverInitializedHandler);
      this.bridgeEventsService.subscribe(
        'clickToDialEntities',
        this.clickToDialEntitiesHandler
      );
      this.bridgeEventsService.subscribe(
        'clickToDial',
        this.clickToDialHandler
      );
      this.bridgeEventsService.subscribe(
        'createNewEntity',
        this.createNewEntity
      );
      this.bridgeEventsService.subscribe(
        'sendNotification',
        this.sendNotification
      );
      this.bridgeEventsService.subscribe('customEvent', this.customEvent);
      this.bridgeEventsService.subscribe('reportTrace', this.reportTrace);

      this.bridgeEventsService.subscribe('onLogout', (async (reason) => {
        await this.disableAllCommands();
        this.sendPresence = false;
        await this.bridgeEventsService.sendEvent('davinciLogout');
        await logout(reason);
      }).bind(this));

      registerOnLogout((async () => {
        this.sendPresence = false;
        this.scapi.bHasLoginCTI = false;
        await this.disableAllCommands();
        await this.bridgeEventsService.sendEvent('davinciLogout');
        await this.removeLocalStorageOnLogout();
        await this.loggerService.logger.pushLogsAsync();
      }).bind(this));

      registerEnableClickToDial(async (enabled) => {
        this.bridgeEventsService.sendEvent('enableClickToDial', enabled);
      });

      this.logger.logDebug(
        'Siebel - Home : Configuration from Siebel App : ' +
          JSON.stringify(this.appConfig)
      );

      registerOnPresenceChanged(this.sendPresenceDetails.bind(this));

      this.readConfig(this.appConfig);

      // Garbage collection
      setInterval(this.garbageCollection, this.garbageCollectionFrequency);

      this.logger.logDebug(
        'Siebel - Home : END : Fetching Siebel App Configuration'
      );
    } catch (error) {
      this.logger.logError(
        'Siebel - Home : ERROR : Fetching Configuration. Error Information : ' +
          JSON.stringify(error)
      );
    }
  }

  @bind
  async handleNotification(message: string, type: NOTIFICATION_TYPE): Promise<void> {
    const fname = 'handleNotification';
    try {
      this.loggerService.log(LOG_LEVEL.Debug, 'registerSendNotification', 'Sending Notification', { message, type });
      this.scapi.ShowStatusText(message);
    } catch (error) {
      this.loggerService.log(LOG_LEVEL.Error, fname, 'Error occurred.', error);
    } finally {
      return Promise.resolve();
    }
  }

  @bind
  private async InvokeCommand(command: ISiebelCommand) {
    let fname = `${this.cname}.InvokeCommand()`;
    let handle;
    this.loggerService.logger.logDebug(`${fname} BEGIN.`);
    this.loggerService.log(LOG_LEVEL.Debug, fname, `Invoke Command: ${command?.name}`, { trackingID: command?.datasetParam?.TrackingID, command });
    this.loggerService.log(LOG_LEVEL.Trace, fname, `Active Interactions:`, this.scapi.interactionToWorkItemMap);
    let commandInteraction = this.currentInteraction;
    if (this.scapi.interactionToWorkItemMap.has(command?.datasetParam?.TrackingID)) {
      this.loggerService.log(LOG_LEVEL.Debug, fname, `Command Interaction found in map: ${command?.datasetParam?.TrackingID}`, this.scapi.interactionToWorkItemMap.get(command?.datasetParam?.TrackingID))
      commandInteraction = this.scapi.interactionToWorkItemMap.get(command?.datasetParam?.TrackingID);
    }
    this.loggerService.log(LOG_LEVEL.Debug, fname, `Command Interaction: ${commandInteraction?.interactionId}`);
    this.loggerService.log(LOG_LEVEL.Trace, fname, `Command Interaction:`, commandInteraction);
    switch (command.name) {
      case SupportedCommands.LogIn:
        this.loggerService.logger.logDebug(`${fname}: LogIn command received. Logging in...`);
        await this.scapi.SetCommands([SupportedCommands.LogIn, SupportedCommands.LogOut], [SCCommandFlag.SC_CF_DISABLED, 0]);
        // this.clientInterface.ShowStatusText("Warning, LogIn is not yet implemented, only simulated.");
        break;
      case SupportedCommands.LogOut:
        this.loggerService.logger.logDebug(`${fname}: LogOut command received. Logging out...`);
        this.sendPresence = false;
        await this.scapi.HandleLogin(false, command.datasetParam['Reason']);
        this.scapi.ShowStatusText("Logging out of DaVinci");
        break;
      case SupportedCommands.MakeCall:
        this.loggerService.logger.logDebug(`${fname}: MakeCall command received. Triggering CTD if phone number is present.`);
        if (command.datasetParam && command.datasetParam.PhoneNumber) {
            let formattedNumber = this.clickToDialFormatPhoneNumber(command.datasetParam.PhoneNumber);
            clickToDial(formattedNumber, null, CHANNEL_TYPES.Telephony);
        }
        break;
      case SupportedCommands.AnswerCall:
        await this.scapi.SetCommands([SupportedCommands.AnswerCall], [SCCommandFlag.SC_CF_CHECKED]);
        this.clickToActService.answerCall(commandInteraction);
        break;
      case SupportedCommands.UnHoldCall:
        await this.scapi.SetCommands([SupportedCommands.UnHoldCall], [SCCommandFlag.SC_CF_CHECKED]);
        this.clickToActService.unholdCall(commandInteraction);
        break;
      case SupportedCommands.HoldCall:
        await this.scapi.SetCommands([SupportedCommands.HoldCall], [SCCommandFlag.SC_CF_CHECKED]);
        this.clickToActService.holdCall(commandInteraction);
        break;
      case SupportedCommands.ReleaseCall:
        await this.scapi.SetCommands([SupportedCommands.ReleaseCall], [SCCommandFlag.SC_CF_CHECKED]);
        this.clickToActService.hangupCall(commandInteraction);
        break;
      case SupportedCommands.SetAgentReady:
        this.clickToActService.setPresence(Presence.Ready, command.datasetParam.Reason ? command.datasetParam.Reason : null);
        break;
      case SupportedCommands.SetAgentNotReady:
        this.clickToActService.setPresence(Presence.NotReady, command.datasetParam.Reason? command.datasetParam.Reason : null);
        break;
      case SupportedCommands.SetAgentBusy:
        this.clickToActService.setPresence(Presence.Busy, command.datasetParam.Reason ? command.datasetParam.Reason : null);
        break;
      case SupportedCommands.SetAgentOtherWork:
        this.clickToActService.setPresence(Presence.OtherWork, command.datasetParam.Reason ? command.datasetParam.Reason : null);
        break;
      case SupportedCommands.SetDisposition:
        await this.dispositionHandler(command);
        break;
      case SupportedCommands.SendDTMFSignal:
        this.clickToActService.sendDTMF(commandInteraction, command.datasetParam.DTMFString != null ? command.datasetParam.DTMFString : '');
        break;
      case SupportedCommands.ChangeNotReadyState:
        this.changeNotReadyStateHandler(command);
        break;
      case SupportedCommands.TransferMute:
        handle = this.interactionToScenario(command.datasetParam[CommandParameter.COMMAND_PARAMETER_TRACKINGID]);

        this.dataStoreService.storeData(handle, command.datasetParam);

        this.clickToActService.blindTransferCall(commandInteraction, command.datasetParam.PhoneNumber);
        break;
      case SupportedCommands.TransferInit:
        handle = this.interactionToScenario(command.datasetParam[CommandParameter.COMMAND_PARAMETER_TRACKINGID]);

        await this.dataStoreService.storeData(handle, command.datasetParam);

        this.clickToActService.warmTransferCall(commandInteraction, command.datasetParam.PhoneNumber, true);
        this.agentModeService.SetCurrentAgentMode(AGENT_MODES.AGENT_MODE_WARM_TRANSFER_INIT);

        break;
      case SupportedCommands.TransferComplete:
        handle = command.datasetParam[CommandParameter.COMMAND_PARAMETER_TRACKINGID];

        this.clickToActService.warmTransferCall(commandInteraction, command.datasetParam.PhoneNumber, false);
        break;
      case SupportedCommands.ConferenceInit:
        handle = this.interactionToScenario(command.datasetParam[CommandParameter.COMMAND_PARAMETER_TRACKINGID]);

        await this.dataStoreService.storeData(handle, command.datasetParam);

        this.clickToActService.conferenceCall(commandInteraction, command.datasetParam.PhoneNumber, true);
        this.agentModeService.SetCurrentAgentMode(AGENT_MODES.AGENT_MODE_CONFERENCE_INIT);

        break;
      case SupportedCommands.ConferenceComplete:
        this.clickToActService.conferenceCall(commandInteraction, command.datasetParam.PhoneNumber, false);
        break;
      case SupportedCommands.ResetState:
        let message: string = 'Agent toolbar has been reset and disabled. Please exit Siebel and enter again.'
        if (command.stringParam != null && command.stringParam.length >= 0) {
          message = command.stringParam;
        }
        this.scapi.SendClientMessage(message);
        this.agentModeService.ResetAll();
        await this.scapi.SetCommandStates(this.commandStatus.NoContacts());
        await this.scapi.SetCommandStates(this.commandStatus.Disabled());
        await this.scapi.RaiseCleanAllWorkItem();
        break;
      case SupportedCommands.ResumeSelectedCall:
        this.resumeSelectedCallHandler(command, commandInteraction);
        break;
      case SupportedCommands.SuspendDeselectedCall:
        this.suspendDeselectedCallHandler(command, commandInteraction);
        break;
      case SupportedCommands.RetrieveCall:
        this.retrieveCallHandler(command, commandInteraction);
        break;
      case SupportedCommands.SetCallback:
        // This will not be implemented
        alert('Creating a callback from the client is not supported.');
        break;
      default:
        // TODO: Remove before release
        this.loggerService.log(LOG_LEVEL.Warning, fname, 'Unrecognized command.', command);
        alert("Unrecognized command!");
    }

    this.loggerService.logger.logDebug(`${fname}: END.`);
  }

  /**
   * * These functions are for handling cases from the InvokeCommand() function
   */

  /**
   * This function is for changing the Reason code without changing the presence state.
   *
   * @private
   * @param {ISiebelCommand} command
   * @memberof HomeComponent
   */
  private changeNotReadyStateHandler(command: ISiebelCommand) {
    const fname = 'changeNotReadyStateHandler';
    try {
      this.loggerService.log(LOG_LEVEL.Debug, fname, 'Command received to change Reason.', { numericalReasonMap: this.numericalReasonMap, command });
      //* This logic is for using the configs to map the numerical reason to a string reason
      let reason: string;
        if (this.numericalReasonMap[command.datasetParam.Reason] != null) {
          if (command.datasetParam.Reason.includes(",")) {
            reason = this.numericalReasonMap[command.datasetParam.Reason].split(",")[0];
            this.loggerService.log(LOG_LEVEL.Warning, fname, 'Reason contains a comma. Using first reason in list.', command.datasetParam.Reason.split(",")[0]);
            sendNotification('Only one reason can be set at a time.', NOTIFICATION_TYPE.Error);
            //! Should we display an Alert in SCAPI too?
          } else {
            reason = this.numericalReasonMap[command.datasetParam.Reason];
            this.loggerService.log(LOG_LEVEL.Debug, fname, 'Reason found in numericalReasonMap.', { numerical: command.datasetParam.Reason, reason });
          }
        } else {
          this.loggerService.log(LOG_LEVEL.Warning, fname, 'Reason not found in numericalReasonMap. Using empty string as reason.', command.datasetParam.Reason);
          reason = '';
        }

        const isReady = this.scapi.IsReady();
        this.loggerService.log(LOG_LEVEL.Debug, fname, 'Is Ready:', isReady);
        if (isReady || reason === '') {
          this.loggerService.log(LOG_LEVEL.Debug, fname, 'Changing presence to Ready.', reason);
          setPresence('Ready');
        } else {
          this.loggerService.log(LOG_LEVEL.Debug, fname, 'Changing presence to Not Ready.', reason);
          setPresence('Not Ready', reason);
        }
    } catch (error) {
      this.loggerService.log(LOG_LEVEL.Error, fname, 'Error occurred.', error);
    }
  }

  /**
   * This function is for leaving the consult state of a call before completing a warm transfer or starting a conference.
   *
   * @private
   * @param {ISiebelCommand} command
   * @memberof HomeComponent
   */
  private retrieveCallHandler(command: ISiebelCommand, interaction: IInteraction) {
    const fname = 'retrieveCallHandler';
    try {
      this.loggerService.log(LOG_LEVEL.Critical, fname, 'Command received to retrieve call.', command);
      if (this.scapi.interactionToWorkItemMap.size >= 2) {
        this.scapi.interactionToWorkItemMap.forEach((currentInteraction, key) => {
          this.loggerService.log(LOG_LEVEL.Debug, fname, 'Checking Interaction:', currentInteraction);
          if (currentInteraction.state === INTERACTION_STATES.OnHold || currentInteraction.state === INTERACTION_STATES.Connected) {
            if (currentInteraction.interactionId !== interaction.interactionId) {
              if (currentInteraction.state === INTERACTION_STATES.OnHold) {
                this.clickToActService.retrieveCall(interaction, currentInteraction);
              } else if (currentInteraction.state === INTERACTION_STATES.Connected) {
                this.clickToActService.retrieveCall(currentInteraction, interaction);
              }
            }
          } else {
            this.loggerService.log(LOG_LEVEL.Trace, fname, `Interaction ${currentInteraction.interactionId} found in map in state ${currentInteraction.state}`);
          }
        });

        this.agentModeService.ResetAll();
        this.bIsConsultCallRetrieved = true;
      } else {
        throw new Error('There must be at least 2 calls to perform the retrieve operation.');
      }
    } catch (error) {
      this.loggerService.log(LOG_LEVEL.Error, fname, 'Error occurred.', error);
    }
  }

  private async dispositionHandler(command: ISiebelCommand) {
    const fname = 'dispositionHandler';
    try{
      this.loggerService.log(LOG_LEVEL.Debug, fname, 'SetDisposition command received.', );
      if (!this.currentInteraction) {
        if (this.interactionToDisposition) {
          if (this.interactionToDisposition.state === INTERACTION_STATES.Disconnected) {
            this.loggerService.log(LOG_LEVEL.Debug, fname, 'interactionToDisposition is disconnected. Adding to dispositionSentDisconnect.');
            this.scapi.dispositionSentDisconnect.add(this.interactionToDisposition.interactionId);
          }
          this.clickToActService.setDisposition(this.interactionToDisposition, command.datasetParam.Code != null ? command.datasetParam.Code : '');
        } else {
          this.loggerService.logger.logError(`${fname}: No interaction to set disposition on.`);
        }
      } else {
        if (this.currentInteraction.state === INTERACTION_STATES.Disconnected) {
          this.loggerService.log(LOG_LEVEL.Debug, fname, 'currentInteraction is disconnected. Adding to dispositionSentDisconnect.');
          this.scapi.dispositionSentDisconnect.add(this.currentInteraction.interactionId);
        }
        this.clickToActService.setDisposition(this.currentInteraction, command.datasetParam.Code != null ? command.datasetParam.Code : '');
      }

      const activeInteractions = this.scapi.getActiveInteractionsFromWorkItemMap();
      if (activeInteractions.length === 0) {
        this.loggerService.log(LOG_LEVEL.Debug, fname, 'No active interactions found. Disabling Disposition button.');
        await this.scapi.SetCommandStates(this.commandStatus.SetDispositionChecked(false));
        const currentPresence = await getPresence();
        const isReady = currentPresence.presence === Presence.Ready;
        if (isReady) {
          this.scapi.SetCommandStates(this.commandStatus.PresenceReady(false));
        } else {
          this.scapi.SetCommandStates(this.commandStatus.PresenceNotReady(false));
        }
        // await this.scapi.SetCommands([SupportedCommands.SetDisposition], [SCCommandFlag.SC_CF_DISABLED]);
      }
    } catch (error) {
      this.loggerService.log(LOG_LEVEL.Error, fname, 'Error occurred.', error);
    }
  }

  private resumeSelectedCallHandler(command: ISiebelCommand, interaction: IInteraction) {
    const fname = 'resumeSelectedCallHandler';
    try {
      this.loggerService.log(LOG_LEVEL.Critical, fname, 'Command received to resume selected call.', command);
      if (this.scapi.interactionToWorkItemMap.size >= 2) {
        this.scapi.interactionToWorkItemMap.forEach((currentInteraction, key) => {
          this.loggerService.log(LOG_LEVEL.Debug, fname, 'Checking Interaction:', currentInteraction);
          if (currentInteraction.state !== INTERACTION_STATES.Disconnected) {
            if (currentInteraction.interactionId !== interaction.interactionId) {
              if (currentInteraction.state === INTERACTION_STATES.OnHold) {
                this.clickToActService.swapCalls(currentInteraction, interaction);
              } else if (currentInteraction.state === INTERACTION_STATES.Connected || currentInteraction.state === INTERACTION_STATES.Initiated) {
                this.clickToActService.swapCalls(interaction, currentInteraction);
              } else {
                this.loggerService.log(LOG_LEVEL.Warning, fname, `Interaction ${currentInteraction.interactionId} found in map in state ${currentInteraction.state}`);
              }
            }
          } else {
            this.loggerService.log(LOG_LEVEL.Trace, fname, `Interaction ${currentInteraction.interactionId} found in map in state ${currentInteraction.state}`);
          }
        });
      }
    } catch (error) {
      this.loggerService.log(LOG_LEVEL.Error, fname, 'Error occurred.', error);
    }
  }

  private suspendDeselectedCallHandler(command: ISiebelCommand, interaction: IInteraction) {
    const fname = 'suspendDeselectedCallHandler';
    try {
      this.loggerService.log(LOG_LEVEL.Critical, fname, 'Command received to suspend deselected call.', command);
      this.clickToActService.holdCall(interaction);
    } catch (error) {
      this.loggerService.log(LOG_LEVEL.Error, fname, 'Error occurred.', error);
    }
  }


  private async displayPresenceControls(ctiPresence: string, ctiReason: string = '') {
    const fname = 'displayPresenceControls';
    try {
      this.loggerService.log(LOG_LEVEL.Debug, fname, 'Presence: ', { ctiPresence, ctiReason });
      let presenceMapped = '';
      if (ctiReason !== '') {
        // Note: Reason is only needed for determining the correct presence from the map
        presenceMapped = this.channelToSiebelPresenceMap[`${ctiPresence}|${ctiReason}`];
      } else {
        presenceMapped = this.channelToSiebelPresenceMap[ctiPresence];
      }
      this.loggerService.log(LOG_LEVEL.Debug, fname, 'Presence Mapped:', presenceMapped);

      let presence = '';
      if (presenceMapped.includes('|')) {
        presence = presenceMapped.split('|')[0];
      } else {
        presence = presenceMapped;
      }
      const dispositionState = this.scapi.GetCommandStatus(SupportedCommands.SetDisposition);
      this.loggerService.log(LOG_LEVEL.Debug, fname, 'Disposition State:', dispositionState);
      const dispositionEnabled: boolean = dispositionState === SCCommandFlag.SC_CF_ENABLED || dispositionState === SCCommandFlag.SC_CF_NOPARAMSOK;
      this.loggerService.log(LOG_LEVEL.Debug, fname, 'Disposition Enabled:', dispositionEnabled);
      // Note: After Call Work is not being enabled because CRMs should not be able to set the agent to ACW
      if (ctiPresence === Presence.Ready) {
        this.loggerService.log(LOG_LEVEL.Debug, fname, 'Setting commands for Ready');
        await this.scapi.SetCommandStates(this.commandStatus.PresenceReady(dispositionEnabled));
      } else if (ctiPresence === Presence.NotReady) {
        this.loggerService.log(LOG_LEVEL.Debug, fname, 'Setting commands for Not Ready');
        await this.scapi.SetCommandStates(this.commandStatus.PresenceNotReady(dispositionEnabled));
      } else if (ctiPresence === Presence.Busy) {
        this.loggerService.log(LOG_LEVEL.Debug, fname, 'Setting commands for Busy');
        await this.scapi.SetCommandStates(this.commandStatus.PresenceBusy(dispositionEnabled));
      } else if (ctiPresence === Presence.OtherWork) {
        this.loggerService.log(LOG_LEVEL.Debug, fname, 'Setting commands for Other Work');
        // Note: OtherWork will always be enabled because it handles multiple reasons
        await this.scapi.SetCommandStates(this.commandStatus.PresenceOtherWork(dispositionEnabled));
      }
      this.loggerService.logger.logDebug(`${this.cname} : ${fname} : END`);
    } catch (e) {
      this.logger.logError(
        `${this.cname} : ${fname} : Failed to change displayed presence controls : Error : `+
          JSON.stringify(e)
      );
    }
  }

  private interactionToScenario(interactionId: string): string {
    for (const scenarioId of Object.keys(this.scenarioInteractionMappings)) {
      if (Object.keys(this.scenarioInteractionMappings[scenarioId]).includes(interactionId)) {
        return scenarioId;
      }
    }
    return interactionId;
  }

  private async disableAllCommands() {
    let allCommands = Object.values(SupportedCommands);

    await this.scapi.SetCommands(allCommands, allCommands.map((command) => SCCommandFlag.SC_CF_DISABLED));
  }

  async sendPresenceDetails(presence: string, reason: string, initiatingApp: string) {
    const fname = 'sendPresenceDetails';
    if (!this.sendPresence) {
      return;
    }

    try {
      this.loggerService.log(LOG_LEVEL.Debug, fname, `Presence: ${presence} | Reason: ${reason} | Initiating App: ${initiatingApp}`);

      await this.displayPresenceControls(presence, reason ? reason : '');
      if (presence.toLowerCase().includes('pending') || reason.toLowerCase().includes('pending')) {
        //! Handle Pending Presences
        return;
      }

      const tempInteraction = {
        messageType: 'Presence',
      };

      const message = {
        sourceSystem: 'DaVinci',
        messageToSend: tempInteraction,
        timePublished: new Date().toLocaleTimeString(),
      };

      const event = {
        contactDetails: message,
      };

      const response = await this.bridgeEventsService.sendEvent(
        'sendContactDetailsInfo',
        event
      );
    } catch (e) {
      this.logger.logError(
        `${this.cname} : ${fname} : ERROR : sendPresenceDetails . More Info : `+
          JSON.stringify(e)
      );
    }
  }

  @bind
  private garbageCollection(): void {
    try {
      this.logger.logLoop(
        'Siebel - Home : START : Garbage Collection'
      );

      this.cleanupScenarios();

      this.logger.logLoop(
        'Siebel - Home : END : Garbage Collection'
      );
    } catch (error) {
      this.logger.logError(
        'Siebel - Home : ERROR : Garbage Collection. Error Information : ' +
          JSON.stringify(error)
      );
    }
  }

  private cleanupScenarios(): void {
    try {
      this.logger.logLoop(
        'Siebel - Home : START : Cleaning Expired Scenarios'
      );

      for (const activityId in this.storageService.activityList) {
        const activity = this.storageService.activityList[activityId];

        // Check if activity is not in recent activities
        if (!this.storageService.recentActivityListContains(activity.ScenarioId)) {
          // Check if LastUpdated is older than threshold
          const date = new Date();
          if ((date.getTime() - new Date(activity.LastUpdated).getTime()) > this.scenarioExpirationTime) {
            this.deleteOrphanedActivity(activity.ScenarioId);
          }
        }
      }

      this.logger.logLoop(
        'Siebel - Home : END : Cleaning Expired Scenarios'
      );
    } catch (error) {
      this.logger.logError(
        'Siebel - Home : ERROR : Cleaning Expired Scenarios. Error Information : ' +
          JSON.stringify(error)
      );
    }


  }

  protected deleteOrphanedActivity(scenarioId: string): void {
    try {
      this.logger.logInformation(
        'Siebel - Home : START : Removing Orphaned Activity With Scenario ID : ' +
          scenarioId
      );
      // this.lastClickToDialIActivityDetails = null;
      if (!this.scenarioInteractionMappings[scenarioId]) {
        this.saveAndStoreActivity(scenarioId);
      }
      this.logger.logInformation(
        'Siebel - Home : END : Removing Orphaned Activity With Scenario ID : ' +
          scenarioId
      );
    } catch (error) {
      this.logger.logError(
        'Siebel - Home : ERROR :  Orphaned Activity With Scenario ID : ' +
          scenarioId +
          '. Error Information : ' +
          JSON.stringify(error)
      );
    }
  }

  private readConfig(config: IAppConfiguration) {
    try {
      this.bridgeEventsService.sendEvent('setConfig', config);
      this.logger.logDebug(
        'Siebel - Home : START : Reading Configuration from Siebel App'
      );
      if (this.appConfig['CallActivity']['variables']['DelayActivitySave'] != null) {
        this.delayActivitySave = this.appConfig['CallActivity']['variables']['DelayActivitySave'];
      }
      if (config['CallActivity']['variables']['ActivityExpirationTime'] != null) {
        this.scenarioExpirationTime = config['CallActivity']['variables']['ActivityExpirationTime'] as number;
      }

      if (config['CallActivity']['variables']['ActivityVerificationFrequency'] != null) {
        this.garbageCollectionFrequency = config['CallActivity']['variables']['ActivityVerificationFrequency'] as number;
      }

      const configPhoneFormat = config.variables['PhoneNumberFormat'];
      if (typeof configPhoneFormat === 'string') {
        const tempFormat = String(configPhoneFormat).toLowerCase();
        this.phoneNumberFormat[tempFormat] = tempFormat;
      } else {
        this.phoneNumberFormat = configPhoneFormat;
      }
      this.quickCommentList = <string[]>(
        (config['CallActivity']
          ? config['CallActivity']['variables']['QuickComments']
          : config['variables']['QuickComments'])
      );
      this.cadActivityMap = config['CallActivity']
        ? config['CallActivity']['variables']['CADActivityMap']
        : config['variables']['CADActivityMap']
        ? config['variables']['CADActivityMap']
        : {};
      for (let i = 0; i < this.quickCommentList.length; i++) {
        this.quickCommentList[i] = this.quickCommentList[i].replace(
          /\\n/g,
          String.fromCharCode(13, 10)
        );
        this.quickCommentList[i] = this.quickCommentList[i].replace(
          /\\t/g,
          String.fromCharCode(9)
        );
      }
      const CADQuickCommentRegex = /\{\{.*?\}\}/g;
      for (let i = 0; i < this.quickCommentList.length; i++) {
        this.quickCommentOptionRequiredCadArray[i] =
          this.quickCommentList[i].match(CADQuickCommentRegex);
      }
      if (config.variables['ClickToDialPhoneReformatMap']) {
        this.clickToDialPhoneReformatMap =
          config.variables['ClickToDialPhoneReformatMap'];
      }
      if (
        config['QuickCreate'] &&
        config['QuickCreate']['variables']['QuickCreateKeyList']
      ) {
        this.QuickCreateEntities =
          config['QuickCreate']['variables']['QuickCreateKeyList'];
        this.DisplayQuickCreate =
          Object.keys(this.QuickCreateEntities).length > 0;
      } else {
        this.DisplayQuickCreate = false;
      }
      if (
        config['CallActivity'] &&
        config['CallActivity']['variables'] &&
        config['CallActivity']['variables']['CallFromObjects']
      ) {
        this.storageService.setCallFromObjects(
          config['CallActivity']['variables']['CallFromObjects']
        );
      }
      if (
        config['CallActivity'] &&
        config['CallActivity']['variables'] &&
        config['CallActivity']['variables']['RegardingObjects']
      ) {
        this.storageService.setRegardingObjects(
          config['CallActivity']['variables']['RegardingObjects']
        );
      }

      if (
        config['CallActivity'] &&
        config['CallActivity']['variables'] &&
        config['CallActivity']['variables']['Subject']
      ) {
        this.storageService.subject = config['CallActivity']['variables']['Subject'];
      }

      if (
        config['PresenceMapping'] &&
        config['PresenceMapping']['variables'] &&
        config['PresenceMapping']['variables']['channelToSiebel']
      ) {
        this.channelToSiebelPresenceMap = config['PresenceMapping']['variables']['channelToSiebel'];
      }

      if (
        config['PresenceMapping'] &&
        config['PresenceMapping']['variables'] &&
        config['PresenceMapping']['variables']['siebelToChannel']
      ) {
        this.siebelToChannelMap = config['PresenceMapping']['variables']['siebelToChannel'];
      }

      if (
        config['PresenceMapping'] &&
        config['PresenceMapping']['variables'] &&
        config['PresenceMapping']['variables']['numericalReasonToString']
      ) {
        this.numericalReasonMap = config['PresenceMapping']['variables']['numericalReasonToString'];
      }

      if (
        config['SCAPI Driver'] &&
        config['SCAPI Driver']['variables'] &&
        config['SCAPI Driver']['variables']['EnableDispositionOnCall']
      ) {
        this.scapi.enableDispositionOnCall = config['SCAPI Driver']['variables']['EnableDispositionOnCall'];
      }

      this.channelTypeMap.inbound = config?.["Siebel Channel Type Maps"]?.["variables"]?.["Inbound Channel Map"];
      this.channelTypeMap.outbound = config?.["Siebel Channel Type Maps"]?.["variables"]?.["Outbound Channel Map"];

      // Channel Type Map
      if (!this.channelTypeMap.inbound) {
        this.channelTypeMap.inbound = {
          [CHANNEL_TYPES[CHANNEL_TYPES.Telephony]]: "Call - Inbound"
        };
      }
      if (!this.channelTypeMap.outbound) {
        this.channelTypeMap.outbound = {
          [CHANNEL_TYPES[CHANNEL_TYPES.Telephony]]: "Call - Outbound"
        };
      }

      this.logger.logDebug(
        'Siebel - Home : END : Reading Configuration from Siebel App'
      );
    } catch (error) {
      this.logger.logError(
        'Siebel - Home : ERROR : Reading Configuration. Config Info : ' +
          JSON.stringify(config) +
          '. Error Information : ' +
          JSON.stringify(error)
      );
    }
  }

  protected formatPhoneNumber(
    inputNumber: string,
    phoneNumberFormat: Object
  ): string {
    try {
      this.logger.logTrace(
        'Siebel - Home : START : Formatting Phone Number. Input Number : ' +
          inputNumber +
          '. Configured Format : ' +
          JSON.stringify(phoneNumberFormat)
      );
      const configuredInputFormats = Object.keys(phoneNumberFormat);
      for (let index = 0; index < configuredInputFormats.length; index++) {
        let formatCheck = true;
        const inputFormat = configuredInputFormats[index];
        const outputFormat = phoneNumberFormat[inputFormat];
        if (inputFormat.length === inputNumber.length) {
          const arrInputDigits = [];
          let outputNumber = '';
          let outputIncrement = 0;
          if (
            (inputFormat.match(/x/g) || []).length !==
            (outputFormat.match(/x/g) || []).length
          ) {
            continue;
          }
          for (let j = 0; j < inputFormat.length; j++) {
            if (inputFormat[j] === 'x') {
              arrInputDigits.push(j);
            } else if (
              inputFormat[j] !== '?' &&
              inputNumber[j] !== inputFormat[j]
            ) {
              formatCheck = false;
              break;
            }
          }
          if (formatCheck) {
            for (let j = 0; j < outputFormat.length; j++) {
              if (outputFormat[j] === 'x') {
                outputNumber =
                  outputNumber + inputNumber[arrInputDigits[outputIncrement]];
                outputIncrement++;
              } else {
                outputNumber = outputNumber + outputFormat[j];
              }
            }
            this.logger.logTrace(
              'Siebel - Home : END : Formatting Phone Number. Input Number : ' +
                inputNumber +
                '. Configured Format : ' +
                JSON.stringify(phoneNumberFormat) +
                '. Output Number : ' +
                outputNumber
            );
            return outputNumber;
          }
        }
      }
    } catch (error) {
      this.logger.logError(
        'Siebel - Home : ERROR : Formatting Phone Number. Input Number : ' +
          inputNumber +
          '. Configured Format : ' +
          JSON.stringify(phoneNumberFormat) +
          '. Error Information : ' +
          JSON.stringify(error)
      );
    }
    this.logger.logTrace(
      'Siebel - Home : END : Formatting Phone Number. Input Number : ' +
        inputNumber +
        '. Configured Format : ' +
        JSON.stringify(phoneNumberFormat) +
        '. Output Number : ' +
        inputNumber
    );
    return inputNumber;
  }

  public checkIfRecentActivitiesExist() {
    try {
      return this.storageService.recentScenarioIdList.length > 0 ? true : false;
    } catch (error) {
      this.logger.logError(
        'Siebel - Home : ERROR : Checking if Recent Activities Exist. Error Information : ' +
          JSON.stringify(error)
      );
    }
  }

  protected async removeLocalStorageOnLogout(reason?: string): Promise<any> {
    for (const activityId in this.storageService.activityList) {
      if (this.storageService.activityList[activityId].IsActive){
          this.saveActivity(this.storageService.activityList[activityId], true, true);
      }
    }
    localStorage.clear();

  }

  protected async sendActivityDetails(activity: IActivity) {
    try {
      const tempActivity = {
        messageType: 'Activity',
        WhoObject: activity.WhoObject,
        WhatObject: activity.WhatObject,
        ChannelType: activity.ChannelType,
        CallDurationInSeconds: activity.CallDurationInSeconds,
        Subject: activity.Subject,
        Description: activity.Description,
        Status: activity.Status,
        ActivityId: activity.ActivityId,
        scenarioId: activity.ScenarioId,
        CadFields: activity.CadFields,
        IsActive: activity.IsActive,
      };

      const message = {
        sourceSystem: 'DaVinci',
        messageToSend: tempActivity,
        timePublished: new Date().toLocaleTimeString(),
      };

      const event = {
        contactDetails: message,
      };

      const response = await this.bridgeEventsService.sendEvent(
        'sendContactDetailsInfo',
          event
      );
      this.logger.logDebug(
        'Siebel - Home : Received send Activity Details response from bridge. Scenario ID : ' +
          activity.ScenarioId +
          ' ' +
          JSON.stringify(response)
      );
    } catch (error) {
      this.logger.logError(
        'Siebel - Home : ERROR : send Activity Details . More Info : ' +
          JSON.stringify(error)
      );
    }
  }

  protected async sendInteractionDetails(interaction: IInteraction) {
    try {
      const tempInteraction = {
        messageType: 'Interaction',
        channelType: CHANNEL_TYPES[interaction.channelType],
        state: INTERACTION_STATES[interaction.state],
        details: interaction.details,
        interactionId: interaction.interactionId,
        scenarioId: interaction.scenarioId,
        direction:
          interaction.direction === INTERACTION_DIRECTION_TYPES.Inbound
            ? 'Inbound'
            : interaction.direction === INTERACTION_DIRECTION_TYPES.Outbound
            ? 'Outbound'
            : 'Internal',
        userFocus: interaction.userFocus,
      };

      const message = {
        sourceSystem: 'DaVinci',
        messageToSend: tempInteraction,
        timePublished: new Date().toLocaleTimeString(),
      };

      const event = {
        contactDetails: message,
      };

      const response = await this.bridgeEventsService.sendEvent(
        'sendContactDetailsInfo',
        event
      );
      this.logger.logDebug(
        'Siebel - Home : Received send Interaction Details response from bridge. Scenario ID : ' +
          interaction.scenarioId +
          ' ' +
          JSON.stringify(response)
      );
    } catch (error) {
      this.logger.logError(
        'Siebel - Home : ERROR : send Interaction Details . More Info : ' +
          JSON.stringify(error)
      );
    }
  }

  private async activateInteractionButtons(interaction: IInteraction) {
    const fname = 'activateInteractionButtons()';

    try {
      let commands, statuses;

      switch (interaction.state) {
        case INTERACTION_STATES.Disconnected:
          commands = [
            SupportedCommands.AnswerCall,
            SupportedCommands.AcceptPreview,
            SupportedCommands.AttachData,
            SupportedCommands.ConferenceComplete,
            SupportedCommands.ConferenceInit,
            SupportedCommands.HoldCall,
            SupportedCommands.ReleaseCall,
            SupportedCommands.ResumeSelectedCall,
            SupportedCommands.RetrieveCall,
            SupportedCommands.SendDTMFSignal,
            SupportedCommands.TransferComplete,
            SupportedCommands.TransferInit,
            SupportedCommands.TransferMute,
            SupportedCommands.UnHoldCall,
            SupportedCommands.SendDTMFSignal,
            SupportedCommands.SetDisposition
          ];
          // TODO: Once Josh adds bSupportOutbound and bSkipCallDisposition setDisposition should only be NoParamsOK if bSupporteOutbound is true and bSkipCallDisposition is false
          statuses = [
            SCCommandFlag.SC_CF_DISABLED,
            SCCommandFlag.SC_CF_DISABLED,
            SCCommandFlag.SC_CF_DISABLED,
            SCCommandFlag.SC_CF_DISABLED,
            SCCommandFlag.SC_CF_DISABLED,
            SCCommandFlag.SC_CF_DISABLED,
            SCCommandFlag.SC_CF_DISABLED,
            SCCommandFlag.SC_CF_DISABLED,
            SCCommandFlag.SC_CF_DISABLED,
            SCCommandFlag.SC_CF_DISABLED,
            SCCommandFlag.SC_CF_DISABLED,
            SCCommandFlag.SC_CF_DISABLED,
            SCCommandFlag.SC_CF_DISABLED,
            SCCommandFlag.SC_CF_DISABLED,
            SCCommandFlag.SC_CF_DISABLED,
            SCCommandFlag.SC_CF_NOPARAMSOK
          ];
          await this.scapi.SetCommands(commands, statuses);
          break;
        case INTERACTION_STATES.Alerting:
          commands = [SupportedCommands.AnswerCall,
                      SupportedCommands.ReleaseCall,
                      SupportedCommands.HoldCall,
                      SupportedCommands.UnHoldCall];
          await this.scapi.SetCommands(commands, [SCCommandFlag.SC_CF_BLINKING, SCCommandFlag.SC_CF_ENABLED, SCCommandFlag.SC_CF_DISABLED, SCCommandFlag.SC_CF_DISABLED]);
          break;
        case INTERACTION_STATES.Connected:
          commands = [SupportedCommands.AnswerCall,
                      SupportedCommands.HoldCall,
                      SupportedCommands.ReleaseCall,
                      SupportedCommands.UnHoldCall,
                      SupportedCommands.SendDTMFSignal
                    ];
          statuses = [SCCommandFlag.SC_CF_DISABLED,
                      SCCommandFlag.SC_CF_ENABLED,
                      SCCommandFlag.SC_CF_ENABLED,
                      SCCommandFlag.SC_CF_DISABLED,
                      SCCommandFlag.SC_CF_ENABLED];
          await this.scapi.SetCommands(commands, statuses);
          break;
        case INTERACTION_STATES.OnHold:
          commands = [SupportedCommands.AnswerCall,
                      SupportedCommands.HoldCall,
                      SupportedCommands.ReleaseCall,
                      SupportedCommands.UnHoldCall,
                      SupportedCommands.SendDTMFSignal
                    ];
          statuses = [SCCommandFlag.SC_CF_DISABLED, SCCommandFlag.SC_CF_DISABLED, SCCommandFlag.SC_CF_ENABLED, SCCommandFlag.SC_CF_ENABLED, SCCommandFlag.SC_CF_DISABLED];
          await this.scapi.SetCommands(commands, statuses);
          break;
        case INTERACTION_STATES.Initiated:
          const disabled = SCCommandFlag.SC_CF_DISABLED;

          commands = [SupportedCommands.AnswerCall,
                      SupportedCommands.HoldCall,
                      SupportedCommands.UnHoldCall,
                      SupportedCommands.ReleaseCall];
          statuses = [disabled, disabled, disabled, SCCommandFlag.SC_CF_ENABLED];
          await this.scapi.SetCommands(commands, statuses);
          break;
        default:
          break;
      }
    } catch (e) {
      this.loggerService.logger.logError(`${this.cname}.${fname}: ${JSON.stringify(e)}`);
    }
  }

  protected async onInteraction(
    interactionX: IInteraction
  ): Promise<SearchRecords> {
    const fname = 'onInteraction';
    let interaction = JSON.parse(JSON.stringify(interactionX));
    try {
      this.scapi.updateInteractionToWorkItemMap(interactionX);
      this.loggerService.log(LOG_LEVEL.Debug, fname, `Interaction received, Original IDs: `, { interactionId: interactionX.interactionId, scenarioId: interactionX.scenarioId });
      this.loggerService.log(LOG_LEVEL.Trace, fname, `Interaction received: `, interaction);
      // TODO: Remove before release
      interaction.interactionId = interactionX.interactionId.replace(/[\sA-Za-z]*/g, "");
      interaction.scenarioId = interactionX.scenarioId.replace(/[\sA-Za-z]*/g, "");

      this.logger.logDebug(
        `${this.cname} : ${fname} : Interaction received: ${JSON.stringify(interaction)}`
      );
      this.logger.logInformation(
        `${this.cname} : ${fname} : Interaction received. Scenario ID : ${interaction.scenarioId} : Interaction State : ${interaction.state}`
      );
      await this.activateInteractionButtons(interaction);
      if (interaction.state == INTERACTION_STATES.Disconnected) {
        this.currentInteraction = null;
        // TODO: Make this configurable or check if this is the behavior we want
        setTimeout(() => {
          this.loggerService.log(LOG_LEVEL.Loop, fname, `Removing interaction from map`, { interaction: interaction });
          this.scapi.removeInteractionFromWorkItemMap(interaction);
        }, 30000);
      } else {
        this.currentInteraction = interaction;
      }

      const scenarioId = interaction.scenarioId;
      if (
        interaction.state !== INTERACTION_STATES.Disconnected &&
        this.storageService.recentActivityListContains(scenarioId)
      ) {
        this.storageService.removeRecentActivity(scenarioId);
      }
      let isNewScenarioId = false;
      let isCTDNumber = false;
      this.storageService.updateCadFields(
        interaction,
        this.cadActivityMap,
        this.cadDisplayConfig
      );

      if (interaction.state == INTERACTION_STATES.Alerting || interaction.state == INTERACTION_STATES.Connected) {

        const updatedSubject = this.buildSubjectText(interaction);

        if (this.storageService.activityList[interaction.scenarioId] != null &&
          this.storageService.activityList[interaction.scenarioId] != undefined &&
          !this.storageService.activityList[interaction.scenarioId].IsSubjectChanged &&
          this.storageService.activityList[interaction.scenarioId].InitialInteractionId == interaction.interactionId) {
          this.storageService.setSubject(updatedSubject, interaction.scenarioId);

          this.storageService.compareActivityFields(interaction.scenarioId);

          const changeEvent = {
            type: 'onSubjectChange',
            value: updatedSubject,
            scenarioID: interaction.scenarioId
          };

          this.changeNotify(JSON.stringify(changeEvent));
        }
      }

      if (
        this.storageService.recentActivityListContains(scenarioId) &&
        this.storageService.currentScenarioId !== scenarioId
      ) {
        this.saveActivity(scenarioId, true, this.enableAutoSave);
        return;
      }

      if (
        interaction.details &&
        interaction.details.fields &&
        interaction.details.fields.Phone &&
        interaction.details.fields.Phone.Value
      ) {
        interaction.details.fields.Phone.Value = this.formatPhoneNumber(
          interaction.details.fields.Phone.Value,
          this.phoneNumberFormat
        );
        let interactionDialString = this.phoneToDialString(interaction.details.fields.Phone.Value);

        // This interaction is marked as click to dial if
        // CTD was triggered and at least one of the following is true:
        //      - The CTD dialstring contains the interaction dialstring
        //      - The interaction dialstring contains the CTD dialstring
        //      - The interaction is NOT inbound

        isCTDNumber = this.clickToDialTriggered &&
                      (interactionDialString.includes(this.lastSearchedCTDNumber) ||
                       this.lastSearchedCTDNumber.includes(interactionDialString) ||
                       interaction.direction != INTERACTION_DIRECTION_TYPES.Inbound);
      }

      isNewScenarioId = this.processIfNewScenario(interaction);

      if (isNewScenarioId) {
        // this.scapi.RaiseNewContactEvent(interaction);
      } else {
        // this.scapi.RaiseContactEvent(interaction);
      }

      if (
        interaction['userFocus'] ||
        (this.storageService.activeScenarioIdList.length === 1 &&
          this.storageService.activeScenarioIdList.indexOf(scenarioId) >= 0)
      ) {
        this.storageService.setCurrentScenarioId(scenarioId);
      }

      // Verify if Hold operations to calcualte the Hold Time
      this.storageService.updateInteractionDurationActivity(interaction.scenarioId, interaction.interactionId, interaction.state === INTERACTION_STATES.Disconnected);
      this.storageService.updateHoldInteractionActivityField(interaction.scenarioId, interaction.interactionId, interaction.state === INTERACTION_STATES.OnHold);

      if (interaction.state === INTERACTION_STATES.Disconnected) {
        if (isCTDNumber) {
          this.clickToDialTriggered = false;
          this.lastSearchedCTDNumber = '';
        }
        this.interactionToDisposition = JSON.parse(JSON.stringify(interaction));
        this.deleteExistingScenario(interaction);
      } else if (
        !(
          interaction.state === INTERACTION_STATES.Alerting &&
          this.screenpopOnAlert === false
        )
      ) {
        if (!this.storageService.searchRecordList[scenarioId]) {
          if (isCTDNumber) {
            this.clickToDialTriggered = false;
            this.updateClickToDialList(scenarioId);
          } else {
            const searchRecord = this.searchAndScreenpop(
              interaction,
              isNewScenarioId
            );

            // Decide on whether or not we want to keep this piece of logic with it uncommented it breaks screenpop on alerting in the finally section of this try catch
            // this.storageService.setsearchRecordList(
            //   searchRecord.toJSON(),
            //   scenarioId
            // );
            this.logger.logDebug(
              `${this.cname} : ${fname} : END : Interaction received: ${JSON.stringify(interaction)}`
            );

            return searchRecord;
          }
        }
      }
    } catch (error) {
      this.logger.logError(
        `${this.cname} : ${fname} : ERROR : On Interaction. Error : ${JSON.stringify(error)}`
      );
    } finally {
      if (interaction.state !== INTERACTION_STATES.Disconnected) {
        // Send every event but disconnected to the Siebel Scripts
        this.loggerService.log(LOG_LEVEL.Information, fname, `Sending Interaction to Siebel`, { interaction: interaction });
        // this.sendInteractionDetails(interaction);
      }
      await this.scapi.OnInteractionButtons(interaction);
      await this.scapi.OnContactEvent(this.interactionToEventCode(interaction), this.interactionToCallStateEvent(interaction), this.scenarioInteractionMappings);
    }

    this.logger.logDebug(
      `${this.cname} : ${fname} : END : Interaction received: ${JSON.stringify(interaction)}`
    );
  }

  private interactionToEventCode(interaction: IInteraction): EVENT_CODES {
    let eventCode: EVENT_CODES;

    switch (interaction.state) {
      case INTERACTION_STATES.Alerting:
        eventCode = EVENT_CODES.EVENT_CONTACT_ENQUEUED;
        break;
      case INTERACTION_STATES.Connected:
        eventCode = EVENT_CODES.EVENT_CONTACT_ANSWERED;
        break;
      case INTERACTION_STATES.Disconnected:
        eventCode = EVENT_CODES.EVENT_CONTACT_DROPPED;
        break;
      case INTERACTION_STATES.Initiated:
        eventCode = EVENT_CODES.EVENT_CONTACT_ENQUEUED;
        break;
      // case INTERACTION_STATES.OnHold:
      default:
        eventCode = EVENT_CODES.EVENT_CONTACT_CHANGED;
        break;
    }

    return eventCode;
  }

  private interactionToCallStateEvent(interaction: IInteraction): CallStateEvent {
    const fname = 'interactionToCallStateEvent';
    try {
      let cadFields = interaction.details.fields;
      let callProperties: CallProperties = {};

      // Create CallProperties. (Copy of all CAD on interaction)

      Object.keys(cadFields).forEach((field) => {
        callProperties[field] = cadFields[field].Value;
      });

      // Add scenario id
      callProperties[CommandParameter.COMMAND_PARAMETER_TRACKINGID] = interaction.scenarioId.replace(/[\sA-Za-z]*/g, "");

      // Create CallParties. Will only contain 1 entry in single-party scenarios
      let callParties: CallParty[] = [];

      if (!interaction.multiPartyState) {
        callParties.push({
          PartyId: callProperties['Phone'],
          PartyProperties: {...callProperties}
        });
      }

      let callObj: {[key: string] : Call;} = {};

      let activeInteractions: IInteraction[] = this.scapi.getActiveInteractionsFromWorkItemMap();
      this.loggerService.log(LOG_LEVEL.Debug, fname, `${activeInteractions.length} Active Interactions`);
      this.loggerService.log(LOG_LEVEL.Trace, fname, `Active Interactions`, { activeInteractions: activeInteractions });
      if (activeInteractions.length > 1) {
        //! Assuming only 2 separate interactions can be active at a time
        let secondInteraction = activeInteractions.find((i) => i.interactionId !== interaction.interactionId);
        this.loggerService.log(LOG_LEVEL.Trace, fname, `Second Interaction`, { secondInteraction: secondInteraction });

        let callProperties2: CallProperties = {};
        callProperties2[CommandParameter.COMMAND_PARAMETER_TRACKINGID] = secondInteraction.scenarioId.replace(/[\sA-Za-z]*/g, "");
        let callParties2: CallParty[] = [];
        if (!secondInteraction.multiPartyState) {
          callParties2.push({
            PartyId: callProperties2['Phone'],
            PartyProperties: {...callProperties2}
          });
        }

        callObj = {
          [interaction.interactionId]: {
            State: this.interactionToConnectionState(interaction),
            CallProperties: callProperties,
            Handle: interaction.interactionId.replace(/[\sA-Za-z]*/g, ""),
            CallParties: callParties
          },
          [secondInteraction.interactionId]: {
            State: this.interactionToConnectionState(secondInteraction),
            CallProperties: callProperties2,
            Handle: secondInteraction.interactionId.replace(/[\sA-Za-z]*/g, ""),
            CallParties: callParties2
          }
        };

      } else {
        callObj = {
          [interaction.interactionId]: {
            State: this.interactionToConnectionState(interaction),
            CallProperties: callProperties,
            Handle: interaction.interactionId.replace(/[\sA-Za-z]*/g, ""),
            CallParties: callParties
          }
        };
      }

      const callState: CallStateEvent = {
        EventHandle: interaction.interactionId.replace(/[\sA-Za-z]*/g, ""), //! This may need to be changed to scenarioId
        EventId: this.interactionToEventCode(interaction),
        ChannelId: 'CTI1',
        Calls: callObj,
        ChannelType: this.channelTypeToSiebelString(interaction.channelType, interaction.direction)
      };

      this.loggerService.log(LOG_LEVEL.Trace, fname, `CallStateEvent`, { callState: callState })

      return callState;
    } catch (error) {
      this.loggerService.log(LOG_LEVEL.Error, fname, `Error converting interaction to CallStateEvent`, { error: error });
    }
  }

  private channelTypeToSiebelString(channelType: CHANNEL_TYPES, direction: INTERACTION_DIRECTION_TYPES): string {
    if (direction === INTERACTION_DIRECTION_TYPES.Inbound) {
      return this.channelTypeMap.inbound[CHANNEL_TYPES[channelType]];
    } else {
      return this.channelTypeMap.outbound[CHANNEL_TYPES[channelType]];
    }
  }

  private interactionToConnectionState(interaction: IInteraction): AMC_CONNECTION_STATES {
    let connectionState: AMC_CONNECTION_STATES;

    switch (interaction.state) {
      case INTERACTION_STATES.Alerting:
        connectionState = AMC_CONNECTION_STATES.AMC_CSTATE_ALERTING;
        break;
      case INTERACTION_STATES.Connected:
        connectionState = AMC_CONNECTION_STATES.AMC_CSTATE_CONNECTED;
        break;
      case INTERACTION_STATES.Disconnected:
        connectionState = AMC_CONNECTION_STATES.AMC_CSTATE_DROPPED;
        break;
      case INTERACTION_STATES.Initiated:
        connectionState = AMC_CONNECTION_STATES.AMC_CSTATE_INITIATED;
        break;
      case INTERACTION_STATES.OnHold:
        connectionState = AMC_CONNECTION_STATES.AMC_CSTATE_HELD;
        break;
    }

    return connectionState;
  }

  private async searchAndScreenpop(
    interaction: IInteraction,
    isNewScenarioId: boolean
  ) {
    if (this.shouldPreformScreenpop(interaction, isNewScenarioId, this.searchLayout.enableMultiSession)) {
      this.logger.logInformation(
        'Siebel - Home : Screen pop on interaction. Scenario ID : ' +
          interaction.scenarioId
      );
      this.logger.logDebug(
        'Siebel - Home : Screen pop on interaction. Interaction Info : ' +
          JSON.stringify(interaction)
      );
      // Type field should be populated to allow for appropriate activity creation
      // "If (interaction has no type, or the type is the empty string) AND (channeltype is not null or undefined) then insert type."
      if ( (!interaction.details ||
           !interaction.details.type ||
           interaction.details.type === "") &&
           interaction.channelType != null) {
            interaction.details.type = CHANNEL_TYPES[interaction.channelType]
          }
      const records = await this.preformScreenpop(interaction);
      this.logger.logDebug(
        'Siebel - Home : Screen pop on interaction. Results : ' +
          JSON.stringify(records)
      );
      return records;
    } else {
      this.logger.logInformation(
        'Siebel - Home : Search on interaction. Scenario ID : ' +
          interaction.scenarioId
      );
      this.logger.logDebug(
        'Siebel - Home : Search on interaction. Interaction Info : ' +
          JSON.stringify(interaction)
      );
      const event = this.generateEventForScreenpop(interaction);
      event['search'] = true;
      event['interaction'] = interaction;


      const screenpopResult = await this.bridgeEventsService.sendEvent(
        'search',
        event
      );
      const records = this.formatCrmResults(screenpopResult);
      this.logger.logDebug(
        'Siebel - Home : Search on interaction. Results after formatting : ' +
          JSON.stringify(records)
      );
      return records;
    }
  }

  protected saveAndStoreActivity(scenarioId: string) {
    this.saveActivity(scenarioId, true, this.enableAutoSave);
          this.storageService.onInteractionDisconnect(
            scenarioId,
            this.enableAutoSave
          );
          delete this.scenarioInteractionMappings[scenarioId];
  }

  protected async deleteExistingScenario(interaction: IInteraction): Promise<void> {
    try {
      this.logger.logDebug(
        'Siebel - Home : START : Removing Scenario ID : ' +
          interaction.scenarioId
      );
      this.lastClickToDialIActivityDetails = null;
      if (this.scenarioInteractionMappings[interaction.scenarioId]) {
        delete this.scenarioInteractionMappings[interaction.scenarioId][
          interaction.interactionId
        ];

        if (
          Object.keys(this.scenarioInteractionMappings[interaction.scenarioId])
            .length === 0 && this.delayActivitySave > 0
        ) {
          await new Promise<void>((resolve, reject) => {
            this.logger.logDebug(
              'Siebel  - Home : START : Delaying Removing Activity. Scenario ID : ' +
                interaction.scenarioId
            );
            const timer = setTimeout(() => {
              resolve();
            }, this.delayActivitySave);
          });
        }

        if (
          Object.keys(this.scenarioInteractionMappings[interaction.scenarioId]).length === 0
        ) {
            this.saveAndStoreActivity(interaction.scenarioId);
            // When the Scenario has been completed send the Disconnected event to the Siebel Scripts
            // this.sendInteractionDetails(interaction);
        }
      } else {
        this.logger.logInformation(
          'Siebel - Home : END : Scenario ID : ' +
          interaction.scenarioId
        );
      }
    } catch (error) {
      this.logger.logError(
        'Siebel - Home : ERROR : Deleting existing Scenario. Scenario ID : ' +
          interaction.scenarioId +
          '. Interaction Info : ' +
          JSON.stringify(interaction) +
          '. Error Information : ' +
          JSON.stringify(error)
      );
    }
  }

  protected processIfNewScenario(interaction: IInteraction): boolean {
    try {
      this.logger.logTrace(
        'Siebel - Home : START : Checking if the interaction is new or existing. Interaction Info : ' +
          JSON.stringify(interaction)
      );
      if (
        !this.scenarioInteractionMappings.hasOwnProperty(
          interaction.scenarioId
        ) &&
        interaction.state !== INTERACTION_STATES.Disconnected &&
        (interaction.state !== INTERACTION_STATES.Alerting || this.screenpopOnAlert)
      ) {
        this.scenarioInteractionMappings[interaction.scenarioId] = {};
        this.scenarioInteractionMappings[interaction.scenarioId][
          interaction.interactionId
        ] = true;
        if (
          this.storageService.activeScenarioIdList.indexOf(
            interaction.scenarioId
          ) < 0
        ) {
          if (this.enableCallActivity) {
            this.storageService.addActivity(this.createActivity(interaction));
            this.saveActivity(
              interaction.scenarioId,
              false,
              this.enableAutoSave
            );
          }
        }
        this.logger.logInformation(
          'Siebel - Home : New Scenario with Scenario ID : ' +
            interaction.scenarioId
        );
        this.logger.logTrace(
          'Siebel - Home : END : Checking if the interaction is new or existing. Interaction Info : ' +
            JSON.stringify(interaction)
        );
        return true;
      } else if (
        this.scenarioInteractionMappings.hasOwnProperty(interaction.scenarioId) &&
        !this.scenarioInteractionMappings[interaction.scenarioId].hasOwnProperty(interaction.interactionId) &&
        interaction.state !== INTERACTION_STATES.Disconnected
      ) {
        this.logger.logTrace(
          'Siebel - Home : Start : Found an existing scenario with no Interaction. Interaction Info : ' +
            JSON.stringify(interaction)
        );
        this.scenarioInteractionMappings[interaction.scenarioId][
          interaction.interactionId
        ] = true;
      }
      this.logger.logTrace(
        'Siebel - Home : END : Checking if the interaction is new or existing. Interaction Info : ' +
          JSON.stringify(interaction)
      );
      return false;
    } catch (error) {
      this.logger.logError(
        'Siebel - Home : ERROR : Checking if the interaction is new or existing. Interaction Info : ' +
          JSON.stringify(interaction) +
          '. Error Information : ' +
          JSON.stringify(error)
      );
    }
  }

  protected buildSubjectText(interaction: IInteraction) {
    this.logger.logTrace(
      'Siebel - Home : START : Building Subject Text. Interaction Info : ' +
        JSON.stringify(interaction)
    );
    let subjectText = '';
    try {
      const channelType = CHANNEL_TYPES[interaction.channelType];
      if (interaction.details.fields) {
        const fields = interaction.details.fields;
        if (fields.Email) {
          subjectText = `${channelType}[${fields.Email.Value}]`;
        } else if (this.storageService.subject) {
          let defaultSubject = this.storageService.subject;

          defaultSubject = defaultSubject.replace(/{(.*?)}/gi, function (x) {
            let replacedKey =   x.replace(/{/gi, '');
            replacedKey =   replacedKey.replace(/}/gi, '');

            let replacedValue = '';
            if (interaction.details.fields[replacedKey]) {
              replacedValue = ' ' + interaction.details.fields[replacedKey].Value + ' ';
              }

              return replacedValue;
            });

            subjectText = defaultSubject.replace(/{/gi, '');
            subjectText = subjectText.replace(/}/gi, '');
            subjectText = subjectText.trim();
            subjectText = subjectText.replace(/\s\s+/g, ' ');
        } else if (fields.Phone) {
          subjectText = `${'Call'}[${fields.Phone.Value}]`;
        } else if (fields.FullName) {
          subjectText = `${channelType}[${fields.FullName.Value}]`;
        }
      }
      this.logger.logInformation(
        'Siebel - Home : Subject text for Scenario ID : ' +
          interaction.scenarioId +
          ' is ' +
          subjectText
      );
      this.logger.logTrace(
        'Siebel - Home : END : Building Subject Text. Interaction Info : ' +
          JSON.stringify(interaction)
      );
    } catch (error) {
      this.logger.logError(
        'Siebel - Home : ERROR : Creating new activity. Scenario ID : ' +
          interaction.scenarioId +
          '. Interaction Info : ' +
          JSON.stringify(interaction) +
          '. Error Information : ' +
          JSON.stringify(error)
      );
    }
    return subjectText;
  }

  protected formatCrmResults(crmResults: ICrmEntity[]): SearchRecords {
    try {
      this.logger.logTrace(
        'Siebel - Home : START : Formatting CRM Results. CRM Results : ' +
          JSON.stringify(crmResults)
      );
      crmResults = crmResults || [];

      const result = new SearchRecords();

      if (
        /*typeof crmResults === 'object'*/ crmResults !== null &&
        crmResults.length
      ) {
        crmResults.forEach((element) => {
          const entity = element.entity;
          const record = new RecordItem(
            entity.Id,
            entity.Type,
            entity.DisplayName
          );
          const searchLayoutObject = this.searchLayout.objects.find(
            (object) => entity.Type === object.objectName
          );

          if (searchLayoutObject) {
            for (const key of Object.keys(entity)) {
              if (key !== 'Id' && key !== 'Type' && key !== 'DisplayName') {
                const phoneField = searchLayoutObject.phoneFields.find(
                  (field) => field.APIName === key
                );
                if (phoneField) {
                  if (!record.getPhone()) {
                    record.setPhone(
                      phoneField.APIName,
                      phoneField.DisplayName,
                      entity[key]
                    );
                  } else if (!record.getOtherPhone()) {
                    record.setOtherPhone(
                      phoneField.APIName,
                      phoneField.DisplayName,
                      entity[key]
                    );
                  } else {
                    record.setField(
                      phoneField.APIName,
                      phoneField.APIName,
                      phoneField.DisplayName,
                      entity[key]
                    );
                  }
                } else {
                  const objectField = searchLayoutObject.objectFields.find(
                    (field) => field.APIName === key
                  );
                  if (objectField) {
                    record.setField(
                      objectField.APIName,
                      objectField.APIName,
                      objectField.DisplayName,
                      entity[key]
                    );
                  }
                }
              }
            }
          }
          result.addSearchRecord(record);
        });
      }
      this.logger.logTrace(
        'Siebel - Home : END : Formatting CRM Results. CRM Results : ' +
          JSON.stringify(crmResults)
      );
      return result;
    } catch (e) {
      this.logger.logError(
        'Siebel - Home : ERROR : Formatting CRM Results. CRM Results : ' +
          JSON.stringify(crmResults) +
          '. Error Information : ' +
          JSON.stringify(e)
      );
      return null;
    }
  }

  protected createActivity(interaction: IInteraction): IActivity {
    try {
      this.logger.logDebug(
        'Siebel - Home : START : Creating new Activity. Scenario ID : ' +
          interaction.scenarioId
      );
      const date = new Date();
      const activity: IActivity = {
        WhoObject: {
          objectType: '',
          displayName: '',
          objectName: '',
          objectId: '',
          url: '',
        },
        WhatObject: {
          objectType: '',
          displayName: '',
          objectName: '',
          objectId: '',
          url: '',
        },
        PhoneNumber: '',
        Subject: this.buildSubjectText(interaction),
        ChannelType: CHANNEL_TYPES[interaction.channelType],
        CallDurationInSeconds: 0,
        NumberOfHolds: 0,
        HoldDurationInSeconds: 0,
        HoldDurationOnInteractions: {},
        DurationOnInteractions: {},
        DirectionCode: interaction.direction,
        Description: '',
        Status: 'Open',
        TimeStamp: date,
        ActivityId: '',
        ScenarioId: interaction.scenarioId,
        InitialInteractionId: interaction.interactionId,
        CadFields: {},
        IsActive: true,
        IsProcessing: false,
        IsUnSaved: false,
        IsSubjectChanged: false,
        IsRecentWorkItemLoading: false,
        LastUpdated: date
      };
      const phoneField = interaction.details.fields.Phone;
      if (phoneField && phoneField.Value) {
        activity.PhoneNumber = phoneField.Value;
      }
      for (const key in this.cadActivityMap) {
        if (interaction.details.fields[key]) {
          if (!activity.CadFields) {
            activity.CadFields = {};
          }
          activity.CadFields[this.cadActivityMap[key]] =
            interaction.details.fields[key].Value;
        }
      }
      this.logger.logDebug(
        'Siebel - Home : New activity Info : ' + JSON.stringify(activity)
      );
      this.logger.logDebug(
        'Siebel - Home : END : Creating new Activity. Scenario ID : ' +
          interaction.scenarioId
      );
      return activity;
    } catch (error) {
      this.logger.logError(
        'Siebel - Home : ERROR : Creating new activity. Scenario ID : ' +
          interaction.scenarioId +
          '. Error Information : ' +
          JSON.stringify(error)
      );
    }
  }

  protected async changeNotify(eventString): Promise<string> {
    try {
      const changeEvent = JSON.parse(eventString);
      const activity = this.storageService.getActivity(changeEvent.scenarioID);
      const tempActivity = {
        NotifyType: changeEvent.type,
        WhoObject: activity.WhoObject,
        WhatObject: activity.WhatObject,
        ChannelType: activity.ChannelType,
        CallDurationInSeconds: activity.CallDurationInSeconds,
        Subject: activity.Subject,
        Description: activity.Description,
        Status: activity.Status,
        ActivityId: activity.ActivityId,
        ScenarioId: activity.ScenarioId,
        CadFields: activity.CadFields,
        IsActive: activity.IsActive,
      };

      this.bridgeEventsService.sendEvent('activityChangeNotify', tempActivity);

      return Promise.resolve('success');
    } catch (error) {
      this.logger.logError(
        'Siebel - Home : ERROR : Change Notify Activity. event : ' +
          eventString +
          '. Error Information : ' +
          JSON.stringify(error)
      );
    }
  }

  protected async saveActivity(
    scenarioId,
    isComplete = false,
    saveToCRM = true
  ): Promise<string> {
    let activity;
    try {
      this.logger.logInformation(
        'Siebel  - Home : START : Saving Activity to CRM. Scenario ID : ' +
          scenarioId
      );

      activity = this.storageService.getActivity(scenarioId);
      if (activity && activity.IsActive && isComplete) {
        this.storageService.updateTotalInteractionTime(scenarioId, this.cadActivityMap);
        this.storageService.setActivityField(scenarioId, 'IsActive', false);
        this.storageService.updateTotalHoldTime(scenarioId, this.cadActivityMap);
      }
      if (!activity || !saveToCRM) {
        return;
      }

      const status = isComplete ? 'Completed' : 'Not Completed';
      this.storageService.setActivityField(scenarioId, 'Status', status);

      this.logger.logDebug(
        'Siebel - Home : Activity Info to be sent to bridge. Scenario ID : ' +
          scenarioId +
          '. Activity Info : ' +
          JSON.stringify(activity)
      );

      activity = await this.bridgeEventsService.sendEvent(
        'saveActivity',
        activity
      );

      this.storageService.setActivityField(scenarioId, 'ActivityId', activity.ActivityId);

      this.logger.logDebug(
        'Siebel - Home : Received Activity Info from bridge. Scenario ID : ' +
          scenarioId +
          '. Activity Info : ' +
          JSON.stringify(activity)
      );

      activity = this.storageService.getActivity(scenarioId);

      this.storageService.activityList[scenarioId].IsProcessing = false;
      this.storageService.updateActivityFields(scenarioId);
      this.storageService.compareActivityFields(scenarioId);
      return Promise.resolve(activity.ActivityId);
    } catch (error) {
      this.storageService.activityList[scenarioId].IsProcessing = false;
      sendNotification(
        'Call activity save failed.',
        NOTIFICATION_TYPE.Error
      );
      this.logger.logError(
        'Siebel - Home : ERROR : Saving Activity to CRM. Scenario ID : ' +
          scenarioId +
          '. Error Information : ' +
          JSON.stringify(error)
      );
    } finally {
      if (activity && saveToCRM) {
        this.sendActivityDetails(activity);
      }
    }
  }

  protected async getRecentWorkItem(scenarioId): Promise<void> {
    try {
      this.logger.logInformation(
        'Siebel - Home : START : Recent Work Item Details from CRM. Scenario ID : ' +
          scenarioId
      );
      const activity = this.storageService.getActivity(scenarioId);
      const recentWorkItem =  await this.bridgeEventsService.sendEvent(
        'getActivity',
        activity
      );
      this.storageService.updateRecentWorkItem(
        recentWorkItem,
        scenarioId,
        this.activityLayout
      );
      this.storageService.activityList[scenarioId].IsRecentWorkItemLoading =
        false;
      this.logger.logInformation(
        'Siebel - Home : END : Recent Work Item Details from CRM. Scenario ID : ' +
          scenarioId
      );
    } catch (error) {
      this.storageService.activityList[scenarioId].IsRecentWorkItemLoading =
        false;
      sendNotification(
        'Error Retrieving Activity Details',
        NOTIFICATION_TYPE.Error
      );
      this.logger.logError(
        'Siebel - Home : ERROR : Recent Work Item Details from CRM. Scenario ID : ' +
          scenarioId +
          '. Error Information : ' +
          JSON.stringify(error)
      );
    }
  }

  protected async getActivityLayout() {
    try {
      this.logger.logTrace(
        'Siebel - Home : START : Fetching Activity Layout'
      );
      this.activityLayout = {};
      for (const item in CHANNEL_TYPES) {
        if (isNaN(Number(item))) {
          this.activityLayout[item] = {};
          this.activityLayout[item]['APIName'] = 'phonecall';
          this.activityLayout[item]['Fields'] = [
            '_regardingobjectid_value',
            'phonecall_activity_parties',
            'Subject',
            'Description',
          ];
          this.activityLayout[item]['LookupFields'] = {
            _regardingobjectid_value: 'WhatObject',
            phonecall_activity_parties: 'WhoObject',
          };
        }
      }
      this.logger.logDebug(
        'Siebel - Home : Activity Layout information : ' +
          JSON.stringify(this.activityLayout)
      );
      this.logger.logTrace('Siebel - Home : END : Fetching Activity Layout');
    } catch (error) {
      this.logger.logError(
        'Siebel - Home : ERROR : Fetching Activity Layout. More information : ' +
          JSON.stringify(error)
      );
    }
  }

  protected agentSelectedCallerInformation(object) {
    this.logger.logDebug(
      'Siebel - Home : START : Agent Selected Entity to screenpop. Entity ID : ' +
        object
    );
    object['interaction'] = { scenarioId: this.storageService.currentScenarioId};
    this.bridgeEventsService.sendEvent(
      'agentSelectedCallerInformation',
      object
    );
    this.logger.logDebug(
      'Siebel - Home : END : Agent Selected Entity to screenpop. Entity ID : ' +
        object
    );
  }

  protected isToolbarVisible(): Promise<boolean> {
    throw new Error('Not Implemented!');
  }

  protected async getSearchLayout(): Promise<SearchLayouts> {
    throw new Error('Not Implemented!');
  }

  protected createNewEntity(type: string) {
    try {
      this.logger.logTrace(
        'Siebel - Home : START : Quick Create Entity Type : ' + type
      );
      let param = {
        entityType: type,
      };
      const activity = this.storageService.getActivity();
      if (activity) {
        if (activity.PhoneNumber) {
          param['phoneNumber'] = activity.PhoneNumber;
        }
        if (type === 'contact') {
          param = this.storageService.RetrieveEntityFromWhatList(
            'account',
            param
          );
        } else if (type === 'incident') {
          param['topic'] = activity.Subject;
          param = this.storageService.RetrieveEntityFromWhatList(
            'account',
            param
          );
          param = this.storageService.RetrieveEntityFromWhatList(
            'contact',
            param
          );
        } else if (type === 'lead') {
          param['topic'] = activity.Subject;
        } else if (type === 'opportunity') {
          param['topic'] = activity.Subject;
          param = this.storageService.RetrieveEntityFromWhatList(
            'account',
            param
          );
          param = this.storageService.RetrieveEntityFromWhatList(
            'contact',
            param
          );
        }
      }
      this.logger.logDebug(
        'Siebel - Home : Quick create request to bridge with params : ' +
          JSON.stringify(param)
      );
      this.bridgeEventsService.sendEvent('createNewEntity', param);
      this.logger.logTrace(
        'Siebel - Home : END : Quick Create Entity Type : ' + param
      );
    } catch (error) {
      this.logger.logError(
        'Siebel - Home : ERROR : Quick Create. Entity Type : ' +
          type +
          '. More Info : ' +
          JSON.stringify(error)
      );
    }
  }

  protected onFocusHandler(entities) {
    this.logger.logDebug('onFocusEvent START: ' + entities);
    clearContextualContacts();
    super.onFocusHandler(entities);
    this.logger.logDebug('onFocusEvent END');

    for (let i = 0; i < entities.length; i++) {
      const activityObject = this.buildActivityDetails(entities[i]);
      if (this.storageService.currentScenarioId) {
        this.storageService.updateWhoWhatLists(
          activityObject,
          this.storageService.currentScenarioId
        );

        this.sendActivityDetails(
          this.storageService.getActivity(this.storageService.currentScenarioId)
        );
      }
      if (this.storageService.workingRecentScenarioId) {
        this.storageService.updateWhoWhatLists(
          activityObject,
          this.storageService.workingRecentScenarioId
        );
      }
    }
  }

  @bind
  protected sendNotification(event: any) {
    sendNotification(event.notification, event.notificationType);
  }

  @bind
  protected customEvent(event: any) {
    // To be implemented when custom events use cases are handled or
    // To be pushed to DaVinci API for other apps to handle the events
    // Temporarly displayed as notification
    sendNotification(
      JSON.stringify(event.result),
      NOTIFICATION_TYPE.Information
    );
  }

  @bind
  protected reportTrace(event: any) {
    switch(event.level) {
      case LOG_LEVEL.Trace :
        this.logger.logTrace(event.message);
        break;
      case LOG_LEVEL.Critical :
        this.logger.logCritical(event.message);
        break;
      case LOG_LEVEL.Debug :
        this.logger.logDebug(event.message);
        break;
      case LOG_LEVEL.Error :
        this.logger.logError(event.message);
        break;
      case LOG_LEVEL.Information :
        this.logger.logInformation(event.message);
        break;
      case LOG_LEVEL.Loop :
        this.logger.logLoop(event.message);
        break;
      case LOG_LEVEL.Warning :
        this.logger.logWarning(event.message);
        break;
      default:
        break; //when log level is log none
    }

  }

  /**
   * Converts a phone number into a dialstring. Strips phone number
   * of all characters that are not numeric.
   */
  private phoneToDialString(phoneNum: string) : string {
    return phoneNum.replace(/[^0-9]/g, '');
  }

  @bind
  protected clickToDialHandler(event: any) {
    let numberToDial = event.number;

    let channelType: CHANNEL_TYPES = parseInt(event.channelType);

    if (isNaN(channelType)) {
      channelType = <CHANNEL_TYPES> <unknown> CHANNEL_TYPES[event.channelType];
    }

    if ([CHANNEL_TYPES.SMS, CHANNEL_TYPES.Telephony].includes(channelType)) {
      numberToDial = this.phoneToDialString(event.number);
    }
    if (event.records) {
      const clickToDialRecord = event.records.entity
        ? JSON.stringify(event.records.entity.CRMinfos)
        : event.records;
      this.lastSearchedCTDNumber = numberToDial;
      const recordsList = JSON.parse(clickToDialRecord);
      this.lastClickToDialRecords = this.formatCrmResults(recordsList);
      for (let i = 0; i < recordsList.length; i++) {
        this.lastClickToDialIActivityDetails = this.buildActivityDetails(
          recordsList[i]
        );
      }
    }
    if (numberToDial) {
      numberToDial = this.clickToDialFormatPhoneNumber(event.number);
      this.clickToDialTriggered = true;
      clickToDial(numberToDial, this.lastClickToDialRecords, channelType);
    }
  }

  protected async processConfig(config: IAppConfiguration) {
    try {
    this.cadPopKeys = getCadPopKeys(
      config.SearchLayout &&
        config.SearchLayout.variables['InteractionSearchFields']
        ? String(config.SearchLayout.variables['InteractionSearchFields'])
        : ''
    );
    this.maxRecordsDefault = <number>config.variables.maxRecordsDefault;
    if (
      config['variables'] &&
      config['variables']['screenpopOnAlerting'] !== undefined
    ) {
      this.screenpopOnAlert = (config['variables']['screenpopOnAlerting']) as boolean;
    }
    if (
      config['CallActivity'] &&
      config['CallActivity']['variables'] &&
      config['CallActivity']['variables']['EnableCallActivity'] !== undefined
    ) {
      this.enableCallActivity = <boolean>(
        config['CallActivity']['variables']['EnableCallActivity']
      );
    }
    if (
      config['CallActivity'] &&
      config['CallActivity']['variables'] &&
      config['CallActivity']['variables']['EnableAutoSave'] !== undefined
    ) {
      this.enableAutoSave = <boolean>(
        config['CallActivity']['variables']['EnableAutoSave']
      );
    }
    this.storageService.maxRecentItems = <Number>(
      (config['CallActivity']
        ? config['CallActivity']['variables']['MaxRecentItems']
        : config['variables']['MaxRecentItems'])
    );
    this.searchLayout = getSearchLayout(config);
    this.AddActivityToSearchLayout();
    this.clickToDialLayout = getClickToDialLayout(config);
    this.cadDisplayConfig = getCadDisplayConfig(config);
    this.storageService.displayCadData = this.cadDisplayConfig.DisplayCad;
    this.bridgeEventsService.sendEvent('setLayouts', [
      this.searchLayout,
      this.clickToDialLayout,
    ]);
  } catch (e) {
    this.logger.logError('Siebel - Home : ERROR : processConfig(). Error Information : ' + JSON.stringify(e));
  }
  }

  protected AddActivityToSearchLayout() {
    const object: ISearchLayoutObject = {
      objectName: 'activity',
      objectFields: [],
      phoneFields: [],
    };
    const activityDisplayFields = {};
    activityDisplayFields['phonecall_activity_parties'] =
      'phonecall_activity_parties';
    activityDisplayFields[
      '_regardingobjectid_value@Microsoft.Dynamics.CRM.lookuplogicalname'
    ] = 'regardingobjectid_value@Microsoft.Dynamics.CRM.lookuplogicalname';
    activityDisplayFields['_regardingobjectid_value'] =
      '_regardingobjectid_value';
    activityDisplayFields['description'] = 'Description';
    activityDisplayFields['subject'] = 'Subject';

    if (activityDisplayFields) {
      for (const displayKey of Object.keys(activityDisplayFields)) {
        object.objectFields.push({
          DisplayName: activityDisplayFields[displayKey],
          APIName: displayKey,
        });
      }
    }
    this.searchLayout.objects.push(object);
  }

  protected async registerForAmcEvents() {
    await super.registerForAmcEvents();
    await registerContextualControls((event) => {
      return clickToDial(event.uniqueId);
    });
  }

  protected getUserInfoHandler(): Promise<string> {
    return this.bridgeEventsService.sendEvent('getUserInfo', null);
  }

  protected buildActivityDetails(entityOBJ): IActivityDetails {
    const entity = entityOBJ.entity;
    const activityObject: IActivityDetails = {
      objectType: '',
      displayName: '',
      objectName: '',
      objectId: '',
      url: '',
    };
    activityObject.objectType = entity.DisplayName;
    activityObject.objectId = entity.Id;
    const entityConfiguration = this.searchLayout.objects.filter(
      (e) => e.objectName === entity.DisplayName
    );
    if (
      entityConfiguration.length > 0 &&
      entityConfiguration[0].objectFields.length > 0
    ) {
      for (const field of entityConfiguration[0].objectFields) {
        if (field.APIName && entity[field.APIName]) {
          const displayName = field.APIName;
          activityObject.displayName = entity[displayName];
          break;
        }
      }
    }
    return activityObject;
  }

  protected getSecondsElapsed(startDate): number {
    try {
      this.logger.logLoop('Siebel - Home : START : Get Seconds Elapsed');
      const EndDate = new Date();
      if (typeof startDate === 'string') {
        startDate = new Date(startDate);
      }
      this.logger.logLoop('Siebel - Home : END : Get Seconds Elapsed');
      return Math.round((EndDate.getTime() - startDate.getTime()) / 1000);
    } catch (error) {
      this.logger.logError(
        'Siebel - Home : ERROR : Get Seconds Elapsed. Start Date : ' +
          startDate +
          '. Error Information : ' +
          JSON.stringify(error)
      );
    }
  }

  @bind
  protected async driverInitializedHandler(event: {
    driverParameters: DriverSettings;
    serviceParameters: ServiceSettings;
  }) {
    const functionName = 'driverInitializedHandler';
    try {
      this.loggerService.log(LOG_LEVEL.Debug, functionName, 'Driver Initializing', { event: event, driverInitialize: this.driverInitialized });
      this.siebelConfig.driverSettings = event.driverParameters;
      this.siebelConfig.serviceSettings = event.serviceParameters;
      const currentPresence: IGetPresenceResult = await getPresence();
      const isACW = currentPresence.presence === "ACW";
      if (!this.driverInitialized) {
        if (this.scapi.bIgnoreLogin) {
          this.loggerService.log(LOG_LEVEL.Critical, functionName, 'Driver not initialized / logged in');
          await this.scapi.HandleLogin(true);
        } else {
          await this.scapi.HandleLogin(true);
          // TODO: Add new login state logic.
          // await this.scapi.OnWorkmodeChangedEvent(parseInt(this.scapi.wstrAgentDefaultStateOnLogin), 0);
        }
        this.scapi.logDefFileConfigs();
        this.driverInitialized = true;
        this.loggerService.log(LOG_LEVEL.Information, functionName, 'Driver initialized / logged in');
      } else {
        this.loggerService.log(LOG_LEVEL.Warning, functionName, 'Driver already initialized / logged in');
      }
    } catch (error) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, 'Failed to initialize driver', error);
    }
  }

  @bind
  protected clickToDialEntitiesHandler(entities) {
    this.logger.logDebug('clickToDialEntitiesHandler START: ' + entities);
    const contacts: { [key: string]: IContextualContact } = {};
    entities.forEach((entity) => {
      entity = entity.entity;
      for (const objectLayout of this.clickToDialLayout.Entities) {
        if (objectLayout.Name === entity.Type) {
          for (const field of objectLayout.PhoneFields) {
            if (entity[field.APIName]) {
              contacts[entity[field.APIName]] = {
                uniqueId: '' + Object.keys(contacts).length,
                firstName: entity[field.APIName],
                channels: [],
              };
              break;
            }
          }
          break;
        }
      }
    });

    let isNew = false;
    for (const key in contacts) {
      if (
        !(
          this.contextualContacts[key] &&
          this.contextualContacts[key].uniqueId === contacts[key].uniqueId &&
          this.contextualContacts[key].firstName === contacts[key].firstName
        )
      ) {
        isNew = true;
        break;
      }
    }

    if (isNew) {
      this.contextualContacts = contacts;
      addContextualContacts(Object.values(contacts));
    }
    this.logger.logDebug('clickToDialEntitiesHandler END');
  }

  protected clickToDialFormatPhoneNumber(number: any) {
    const configuredInputFormats = Object.keys(
      this.clickToDialPhoneReformatMap
    );
    for (let i = 0; i < configuredInputFormats.length; i++) {
      let formatCheck = true;
      if (number.length === configuredInputFormats[i].length) {
        // Length of incoming number matches length of a configured input format
        // Now Validate # of X's in input/output
        const inputFormat = configuredInputFormats[i];
        const outputFormat =
          this.clickToDialPhoneReformatMap[configuredInputFormats[i]];
        const arrInputDigits = [];
        let outputNumber = '';
        let outputIncrement = 0;
        if (
          (inputFormat.match(/x/g) || []).length !==
          (outputFormat.match(/x/g) || []).length
        ) {
          continue;
        }
        if (
          (inputFormat.match(/\(/g) || []).length !==
          (number.match(/\(/g) || []).length
        ) {
          continue;
        }
        if (
          (inputFormat.match(/-/g) || []).length !==
          (number.match(/-/g) || []).length
        ) {
          continue;
        }
        for (let j = 0; j < inputFormat.length; j++) {
          if (inputFormat[j] === 'x') {
            arrInputDigits.push(j);
          } else if (inputFormat[j] !== '?' && number[j] !== inputFormat[j]) {
            formatCheck = false;
            break;
          }
        }
        if (formatCheck) {
          for (let k = 0; k < outputFormat.length; k++) {
            if (outputFormat[k] === 'x') {
              outputNumber =
                outputNumber + number[arrInputDigits[outputIncrement]];
              outputIncrement++;
            } else {
              outputNumber = outputNumber + outputFormat[k];
            }
          }
          return outputNumber;
        }
      }
    }
    return number;
  }

  protected updateClickToDialList(scenarioId: string): void {
    try {
      this.logger.logTrace(
        'Siebel - Home : START : Update Click To Dial Who/What Lists. Scenario ID : ' +
          scenarioId
      );
      this.storageService.setsearchRecordList(
        this.lastClickToDialRecords.toJSON(),
        scenarioId
      );
      if (this.lastClickToDialIActivityDetails) {
        this.storageService.updateWhoWhatLists(
          this.lastClickToDialIActivityDetails,
          scenarioId
        );

        // notify the latest activity changes
        this.sendActivityDetails(this.storageService.getActivity(scenarioId));
      }
      this.logger.logTrace(
        'Siebel - Home : END : Update Click To Dial Who/What Lists. Scenario ID : ' +
          scenarioId
      );
    } catch (error) {
      this.logger.logError(
        'Siebel - Home : ERROR : Updating Click to Dial Who/What Lists. Scenario ID : ' +
          scenarioId +
          '. Error Information : ' +
          JSON.stringify(error)
      );
    }
  }
}
